import React from "react"
import styled from "styled-components"
import { color } from "./colors"
import parse from "html-react-parser"

const Wrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 24px;
  height: 24px;
  color: ${color.secondary};
  background-color: ${color.red};
  border: 3px solid ${color.secondary};
  border-radius: 100%;
  user-select: none;
  transform: translate(-50%, -50%);

  &:hover {
    z-index: 1;
  }

  &::before {
    content: "";
    width: 16px;
    height: 16px;
    border-width: 4px;
    border-style: solid;
    border-top-color: transparent;
    border-left-color: transparent;
    border-right-color: transparent;
    transform: translate(-50%, -50%);
    position: absolute;
    top: -8px;
    left: 50%;
  }
`

const Name = styled.p`
  position: absolute;
  top: -60px;
  left: 50%;
  transform: translateX(-50%);
  color: ${color.red};
  text-shadow: 1px 1px 1px #fefbf0;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  line-height: 1.1;
  white-space: nowrap;
`

const Marker = props => {
  const { color, text } = props
  const formatedText = parse(text)

  return (
    <Wrapper
      style={{ backgroundColor: color, cursor: "pointer" }}
      title="UL. SIENKIEWICZA 6B/1"
    >
      <Name>{formatedText}</Name>
    </Wrapper>
  )
}

export default Marker
