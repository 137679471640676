import React from "react"
import styled from "styled-components"
import FormField from "../molecules/form-field"
import Button from "../atoms/button"
import { color } from "../../components/colors"

const FormLayout = () => (
  <Form>
    <div>
      <FormField label="Imię" />
      <FormField label="Adres e-mail" />
      <FormField textArea label="Wiadomość" />
      <Button margin="16px 0 0 0" content={{ url: "#", text: "Wyślij" }} />
    </div>
  </Form>
)

const Form = styled.form`
  width: 100%;
  max-width: 470px;
  background-color: #fff;
  padding: 10px;
  margin: 0 auto;

  @media (min-width: 992px) {
    margin: 0 0 0 auto;
    max-width: 370px;
  }

  & > div {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 22px;
    border: 1px solid ${color.blueLight};
  }
`

export default FormLayout
