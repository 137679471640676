import * as React from "react"
import { Link } from "gatsby"
import { graphql } from "gatsby"
import { Parallax, Background } from "react-parallax"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Button from "../atomic/atoms/button"
import Form from "../atomic/organisms/form"
import styled from "styled-components"
import ContentCenter from "../atomic/partials/content-center"
import ContentLeft from "../atomic/partials/content-left"
import LeftContentRightObject from "../atomic/partials/left-content-right-object"
import LeftObjectRightContent from "../atomic/partials/left-object-right-content"
import Container from "../atomic/partials/container"
import { color } from "../components/colors"
import GoogleMap from "../components/map"
import Gallery from "../components/gallery"
import Arrow from "../images/arrow.inline.svg"

const Top = styled.section`
  width: 100%;
  position: relative;

  .react-parallax {
    height: 700px;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.4);
    }

    .gatsby-image-wrapper img {
      top: 50%;
      left: 50%;
      width: auto;
      object-fit: cover;
      object-position: center center;
      transform: translate(-50%, -50%);
    }
  }

  .react-parallax-background-children {
    width: 100%;
    top: -130px;
  }
`

const Section = styled.section`
  position: relative;

  &.image-sction-1 {
    .react-parallax {
      height: 350px;
    }
  }

  &.image-sction-3 {
    .react-parallax {
      height: 500px;
    }
  }

  &.about-sction {
    padding: 100px 0;

    p:first-child {
      font-size: 22px;
      line-height: 30px;
      margin-bottom: 40px;
    }

    p:not(:first-child) {
      font-size: 19px;
      line-height: 27px;
      margin-bottom: 24px;

      span {
        color: ${color.primary};
      }
    }
  }

  &.gallery-section {
    padding-top: 36px;

    span {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      max-width: 360px;
      padding: 8px 22px;
      border: 4px solid ${color.blueLight};
      margin-right: auto;
      font-size: 30px;
      line-height: 38px;
      text-transform: uppercase;
    }
  }

  &.form-section {
    .react-parallax {
      position: absolute !important;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

    .form-overlay {
      h2,
      p {
        color: #fff;
      }

      h2 {
        font-size: 36px;
        line-height: 44px;
        margin-bottom: 18px;
      }

      p {
        font-size: 18px;
        line-height: 26px;
        margin-bottom: 0;
      }

      svg {
        display: none;
        transform: scale(-1, 1);
        height: 115px;
        position: relative;
        top: 32px;
        left: 160px;

        path {
          fill: #fff;
        }

        @media (min-width: 992px) {
          display: block;
        }
      }
    }
  }

  &.address-section {
    padding: 100px 0;

    .gatsby-image-wrapper {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: 0 !important;
    }

    h2 {
      color: ${color.grey};
      font-size: 36px;
      line-height: 42px;

      @media (min-width: 992px) {
        text-align: right;
      }
    }

    ul {
      list-style-type: none;

      li {
        color: ${color.grey};
        font-size: 18px;
        line-height: 26px;
        margin-top: 17px;

        @media (min-width: 992px) {
          text-align: right;
        }
      }
    }

    svg {
      display: none;
      height: 115px;
      position: relative;
      top: -15px;

      path {
        fill: ${color.grey};
      }

      @media (min-width: 992px) {
        display: block;
      }
    }
  }

  .react-parallax {
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.4);
    }

    .gatsby-image-wrapper img {
      top: 50%;
      left: 50%;
      width: auto;
      object-fit: cover;
      object-position: center center;
      transform: translate(-50%, -50%);
    }
  }

  .react-parallax-background-children {
    width: 100%;
  }
`

const Overlay = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  &.top-overlay {
    display: flex;
    flex-direction: column;
    padding-top: 70px;

    .top-content {
      flex-grow: 1;
      display: flex;
      justify-content: center;
      align-items: center;

      @media (min-width: 576px) {
        justify-content: flex-end;
      }
    }
  }

  &.form-overlay {
    padding: 100px 0 60px;
    position: static;
  }
`

const Title = styled.h1`
  text-align: right;
  color: #fff;
  font-size: 40px;
  line-height: 48px;
  text-transform: uppercase;

  @media (min-width: 576px) {
    font-size: 60px;
    line-height: 68px;
  }
`

const LogoWrapper = styled(Link)`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 99;

  @media (min-width: 576px) {
    left: 0;
    transform: none;
    max-width: 180px;
  }

  @media (min-width: 1200px) {
    max-width: 320px;
  }
`

const MenuWrapper = styled.nav`
  width: 100%;
`

const NavList = styled.ul`
  display: none;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  list-style: none;
  margin: 0;

  @media (min-width: 992px) {
    display: flex;
  }

  li {
    margin-bottom: 0;

    a {
      color: #fff;
      font-size: 16px;
      font-height: 1;
      font-weight: 700;
      text-transform: uppercase;
      text-decoration: none;
      transition: color 0.3s ease;

      &:hover {
        color: ${color.secondary};
      }
    }
  }

  li:not(:last-child) {
    margin-right: 12px;

    @media (min-width: 1200px) {
      margin-right: 16px;
    }
  }
`

const IndexPage = ({ data }) => (
  <Layout>
    <Seo title="Home" />
    <Top>
      <Parallax strength={300}>
        <Background className="custom-bg">
          <StaticImage
            src="../images/bg_01.jpg"
            //quality={85}
            //formats={["AUTO", "WEBP", "AVIF"]}
            alt="A Gatsby astronaut"
            style={{ marginBottom: `1.45rem` }}
          />
        </Background>
      </Parallax>
      <Overlay className="top-overlay">
        <Container className="top-menu">
          <LogoWrapper to="/">
            <StaticImage
              src="../images/logo.png"
              //quality={85}
              //formats={["AUTO", "WEBP", "AVIF"]}
              alt="A Gatsby astronaut"
              style={{ marginBottom: `1.45rem` }}
            />
          </LogoWrapper>
          <MenuWrapper>
            <NavList>
              <li>
                <Link to="wladze">WŁADZE</Link>
              </li>
              <li>
                <Link to="/akty-prawne">AKTY PRAWNE</Link>
              </li>
              <li>
                <Link to="/przetargi">PRZETARGI</Link>
              </li>
              <li>
                <Link to="/ogloszenia">OGŁOSZENIA</Link>
              </li>
              <li>
                <Link to="/aktualnosci">AKTUALNOŚCI</Link>
              </li>
              <li>
                <Link to="/inwestycje">INWESTYCJE</Link>
              </li>
            </NavList>
          </MenuWrapper>
        </Container>
        <Container className="top-content">
          <Title>Zapraszamy</Title>
        </Container>
      </Overlay>
    </Top>
    <Section className="about-sction">
      <ContentCenter>
        <p>Szanowni Państwo</p>
        <p>
          Wszelkie informacje i wyjaśnienia uzyskacie bezpośrednio w biurze
          Spółdzielni,
        </p>
        <p>
          w godzinach od <span>7:00</span> - <span>15:00</span> w poniedziałki,
          środy, czwartki i piątki, a we wtorki od <span>9:00</span> -{" "}
          <span>17:00</span>.
        </p>
        <p>
          Prezes przyjmuje interesantów w każdy wtorek w godzinach od{" "}
          <span>15:00</span> - <span>17:00</span>
          <span>.</span>
        </p>
      </ContentCenter>
    </Section>
    <Section className="image-sction-1">
      <Parallax strength={500}>
        <Background className="custom-bg">
          <StaticImage
            src="../images/bg_02.jpg"
            //quality={85}
            //formats={["AUTO", "WEBP", "AVIF"]}
            alt="A Gatsby astronaut"
            style={{ marginBottom: `1.45rem` }}
          />
        </Background>
      </Parallax>
    </Section>
    <Section className="gallery-section">
      <ContentLeft>
        <span>Galeria</span>
      </ContentLeft>
      <ContentCenter>
        {/* <p>-Galeria-</p> */}
        <Gallery images={data.wpPage.galeria} />
      </ContentCenter>
    </Section>
    <Section className="form-section">
      <Parallax strength={300}>
        <Background className="custom-bg">
          <StaticImage
            src="../images/bg_03.jpg"
            //quality={85}
            //formats={["AUTO", "WEBP", "AVIF"]}
            alt="A Gatsby astronaut"
            style={{ marginBottom: `1.45rem` }}
          />
        </Background>
      </Parallax>
      <Overlay className="form-overlay">
        <LeftContentRightObject object={<Form />}>
          <h2>MASZ PYTANIA?</h2>
          <p>SKONTAKTUJ SIĘ Z NAMI,</p>
          <p>ODPOWIEMY NAJSZYBCIEJ JAK TO TYLKO MOŻLIWE</p>
          <Arrow />
        </LeftContentRightObject>
      </Overlay>
    </Section>
    <Section className="address-section">
      <StaticImage
        src="../images/map.png"
        //quality={85}
        //formats={["AUTO", "WEBP", "AVIF"]}
        alt="A Gatsby astronaut"
        style={{ marginBottom: `1.45rem` }}
      />
      <LeftObjectRightContent object={<GoogleMap />}>
        <h2>NASZ ADRES</h2>
        <ul>
          <li>SPÓŁDZIELNIA MIESZKANIOWA "TARAS PÓŁNOC"</li>
          <li>UL. SIENKIEWICZA 6B/1</li>
          <li>74-101 GRYFINO</li>
        </ul>
        <Arrow />
      </LeftObjectRightContent>
    </Section>
    <Section className="image-sction-3">
      <Parallax strength={300}>
        <Background className="custom-bg">
          <StaticImage
            src="../images/bg_04.jpg"
            //quality={85}
            //formats={["AUTO", "WEBP", "AVIF"]}
            alt="A Gatsby astronaut"
            style={{ marginBottom: `1.45rem` }}
          />
        </Background>
      </Parallax>
    </Section>
  </Layout>
)

export default IndexPage

export const pageQuery = graphql`
  query WordpressImages {
    wpPage(id: { eq: "cG9zdDo2NA==" }) {
      id
      galeria {
        galeria {
          mediaItemUrl
        }
      }
    }
  }
`
