import React, { useState, useCallback } from "react"
import Gallery from "react-photo-gallery"
import Carousel, { Modal, ModalGateway } from "react-images"
import styled from "styled-components"

const Wrapper = styled.div`
  padding: 48px 0 148px;

  img {
    object-fit: cover;
  }
`

function PhotoGallery({ images }) {
  const [currentImage, setCurrentImage] = useState(0)
  const [viewerIsOpen, setViewerIsOpen] = useState(false)

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index)
    setViewerIsOpen(true)
  }, [])

  const closeLightbox = () => {
    setCurrentImage(0)
    setViewerIsOpen(false)
  }

  const randomIntFromInterval = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1) + min)
  }

  const imgList = []
  imgList.push(
    images.galeria.map((photo, i) => ({
      src: photo.mediaItemUrl
        .replace("uploads/", "webp-express/webp-images/uploads/")
        .replace(".jpg", ".jpg.webp"),
      width: randomIntFromInterval(2, 5),
      height: 2,
    }))
  )

  return (
    <Wrapper>
      <Gallery photos={imgList[0]} columns={5} onClick={openLightbox} />
      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              views={imgList[0].map(x => ({
                ...x,
                srcset: x.srcSet,
                caption: x.title,
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </Wrapper>
  )
}
export default PhotoGallery
