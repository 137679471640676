import React from "react"
import GoogleMapReact from "google-map-react"
import Marker from "./map-marker"
import Button from "../atomic/atoms/button"

const defaultProps = {
  center: {
    // lat: 53.426,
    // lng: 14.547,
    lat: 53.250460605912586,
    lng: 14.497001097329568,
  },
  zoom: 15,
}

const GoogleMap = () => (
  <div
    style={{
      height: "100%",
      width: "100%",
      display: "flex",
      flexDirection: "column",
    }}
  >
    <GoogleMapReact
      bootstrapURLKeys={{ key: "AIzaSyD-6KzCbbMaTltQMvjhdcW-6hHMgmWBcYA" }}
      defaultCenter={defaultProps.center}
      defaultZoom={defaultProps.zoom}
    >
      <Marker
        lat={defaultProps.center.lat}
        lng={defaultProps.center.lng}
        text={"Spółdzielnia Mieszkaniowa <br /> Taras Północ"}
      />
    </GoogleMapReact>
    <Button
      content={{
        text: "OTWÓRZ WIĘKSZĄ MAPĘ",
        url:
          "https://www.google.pl/maps/place/Taras+P%C3%B3%C5%82noc.+Sp%C3%B3%C5%82dzielnia+Mieszkaniowa/@53.250438,14.4947041,17z/data=!3m1!4b1!4m5!3m4!1s0x47aa00f0c4a1b01d:0x7ff1a3c91c664f1e!8m2!3d53.250438!4d14.4968928",
      }}
      margin="10px 0 0 auto"
    />
  </div>
)

export default GoogleMap
